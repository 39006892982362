import React, { useState } from 'react';
import { SolarButtonContainer } from './styles';

const SolarButton = ({ title, slug, setOpen, open, index }) => {
   return (
      <>
         <SolarButtonContainer
            onClick={() => setOpen(open === index ? 'null' : index)}
            aria-controls={`example-collapse-text-${index}`}
            aria-expanded={open}
         >
            {title}
         </SolarButtonContainer>
      </>
   );
};

export default SolarButton;
