import {
      RadiatorSliderContainer,
      RadiatorSliderImage,
      RadiatorSliderInfo
} from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

const RadiatorSlider = () => {
      return (
            <>
                  <RadiatorSliderContainer>
                        <Container>
                              <h5 className="mb-3">
                                    Cool radiators and towel rails
                              </h5>
                              <Row>
                                    <Col lg="9">
                                          {' '}
                                          <Swiper
                                                slidesPerView={1}
                                                spaceBetween={10}
                                                breakpoints={{
                                                      640: {
                                                            slidesPerView: 2,
                                                            spaceBetween: 20
                                                      },
                                                      768: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 40
                                                      },
                                                      1024: {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20
                                                      }
                                                }}
                                                className="mySwiper"
                                          >
                                                <SwiperSlide>
                                                      <RadiatorSliderImage>
                                                            <img
                                                                  src="/images/img/radiator/5.jpg"
                                                                  alt=""
                                                                  className="img-fluid"
                                                            />
                                                      </RadiatorSliderImage>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                      <RadiatorSliderImage>
                                                            <img
                                                                  src="/images/img/radiator/2.jpg"
                                                                  alt=""
                                                                  className="img-fluid"
                                                            />
                                                      </RadiatorSliderImage>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                      <RadiatorSliderImage>
                                                            <img
                                                                  src="/images/img/radiator/3.jpg"
                                                                  alt=""
                                                                  className="img-fluid"
                                                            />
                                                      </RadiatorSliderImage>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                      <RadiatorSliderImage>
                                                            <img
                                                                  src="/images/img/radiator/4.jpg"
                                                                  alt=""
                                                                  className="img-fluid"
                                                            />
                                                      </RadiatorSliderImage>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                      <RadiatorSliderImage>
                                                            <img
                                                                  src="/images/img/radiator/5.jpg"
                                                                  alt=""
                                                                  className="img-fluid"
                                                            />
                                                      </RadiatorSliderImage>
                                                </SwiperSlide>
                                          </Swiper>
                                    </Col>
                                    <Col lg="3">
                                          <RadiatorSliderInfo>
                                                <h3>10</h3>
                                                <h4>Years Warrenty</h4>
                                          </RadiatorSliderInfo>
                                    </Col>
                              </Row>
                        </Container>
                  </RadiatorSliderContainer>
            </>
      );
};

export default RadiatorSlider;
