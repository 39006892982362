import React from 'react';
import {
      RadiatorInfoContainer,
      RadiatorInfoContent,
      RadiatorInfoImage
} from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import SolarButton from '../../../common/SolarCommonLayout/SolarButton';

const RadiatorInfo = () => {
      return (
            <>
                  <RadiatorInfoContainer>
                        <Container>
                              <Row className="g-5">
                                    <Col>
                                          <RadiatorInfoContent>
                                                <h2 className="py-4 pb-2">
                                                      <span>Radiator </span> for
                                                      winter.
                                                      <br />
                                                      Art all year round.
                                                </h2>
                                                <p className="text-justify">
                                                      Whether you're planning a
                                                      renovation or searching
                                                      for your perfect piece,
                                                      we've got you covered.
                                                      From colourful and
                                                      contemporary designer
                                                      radiators to classical and
                                                      cosy column radiators, to
                                                      a variety of efficient
                                                      electric radiators and
                                                      more, you'll find a wide
                                                      selection of affordable
                                                      radiators & towel rails
                                                      that make the functional,
                                                      beautiful.
                                                </p>
                                                <p className="text-justify">
                                                      And with fast & free
                                                      delivery, you're closer to
                                                      cool than you might
                                                      think... Curate your space
                                                      with new radiators from
                                                      Innmotek. We’re a warm
                                                      bunch, and we think
                                                      heating your home should
                                                      be as smooth as possible -
                                                      so we’ll always provide
                                                      free delivery & returns,
                                                      same day dispatch, and
                                                      knowledgeable friendly
                                                      customer service when you
                                                      need it.
                                                </p>
                                                <SolarButton
                                                      title={
                                                            'Download Catalogue'
                                                      }
                                                      index={2}
                                                />
                                          </RadiatorInfoContent>
                                    </Col>
                                    <Col lg="5">
                                          <RadiatorInfoImage>
                                                <img
                                                      src="/images/img/radiator/desc.jpg"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </RadiatorInfoImage>
                                    </Col>
                              </Row>
                        </Container>
                  </RadiatorInfoContainer>
            </>
      );
};

export default RadiatorInfo;
