import { propTypes } from 'react-bootstrap/esm/Image';
import styled from 'styled-components';

export const RadiatorInfoContainer = styled.div``;
export const RadiatorInfoImage = styled.div``;
export const RadiatorInfoContent = styled.div`
      h2 {
            span {
                  color: ${(props) => props.theme.secondary};
            }
      }
      p {
            text-align: justify;
      }
`;
