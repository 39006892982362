export const categorytabs = [
   {
      id: 1,
      title: 'Combo Hot Water Heat Pumps',
      subTitle: 'Residental',
      image: '/images/img/category/residential.jpg',
      icon: '/images/svg/residental.svg',
      slug: 'category/residential/residential'
   },
   {
      id: 2,
      title: 'Split Hot Water Heat Pump',
      subTitle: 'Commercial',
      image: '/images/img/category/commercial.png',
      icon: '/images/svg/commercial.svg',
      slug: '/category/residential/residential'
   },
   {
      id: 3,
      title: 'Heat Pump Air Dryer',
      subTitle: 'Industrial',
      image: '/images/img/category/industrial.png',
      icon: '/images/svg/industrial.svg',
      slug: '/category/industrial/industrial'
   },
   {
      id: 4,
      title: 'Swimming Pool Heat Pump',
      subTitle: 'Pool & Spa',
      image: '/images/img/category/poolandspa.jpg',
      icon: '/images/svg/swimming-pool (1).png',
      slug: '/category/pool-and-spa'
   }
];
