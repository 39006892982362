import React, { useState } from 'react';
import {
   Col,
   Container,
   Nav,
   NavItem,
   Row,
   Tab,
   TabContent
} from 'react-bootstrap';
import { TestimonialHeading } from '../../../../styles/frontend/home';
import { CategorySection, CategoryTab } from './styles';
import { categorytabs } from '../../../../data/categoryTab';
import { Link, NavLink } from 'react-router-dom';

const CategoryBox = () => {
   const [hoveredCategory, setHoveredCategory] = useState(null);

   const handleCategoryHover = (index) => {
      setHoveredCategory(index);
   };
   const handleCategoryLeave = (index) => {
      setHoveredCategory(null);
   };

   const handleButtonClick = (slug) => {};
   return (
      <>
         <CategorySection>
            <Container>
               <TestimonialHeading>
                  <h2>
                     Our Category <span className="ms-2"></span>
                  </h2>
                  <h3>Explore Our Range of Category.</h3>
               </TestimonialHeading>
               <Tab.Container id="myTab" defaultActiveKey="first">
                  <Row className="mt-5  ">
                     <Col lg={5} md={5}>
                        <CategoryTab>
                           <Nav className="gap-4">
                              {categorytabs.map((item, index) => (
                                 <NavItem
                                    key={index}
                                    onMouseEnter={() =>
                                       handleCategoryHover(index)
                                    }
                                    onMouseLeave={handleCategoryLeave}
                                 >
                                    <Nav.Link
                                       as="button"
                                       className={
                                          hoveredCategory === index
                                             ? 'active'
                                             : ''
                                       }
                                       onClick={() =>
                                          handleButtonClick(item.slug)
                                       }
                                    >
                                       <Link
                                          className="category__content"
                                          to={item.slug}
                                       >
                                          <div className="category__icon">
                                             <img
                                                src={item.icon}
                                                alt={
                                                   item.title && item.subTitle
                                                }
                                                width="100%"
                                                height="100%"
                                             />
                                          </div>
                                          <div className="category__title mt-2">
                                             <span>[ {item.title} ]</span>
                                             <h3>{item.subTitle}</h3>
                                          </div>
                                       </Link>
                                    </Nav.Link>
                                 </NavItem>
                              ))}
                           </Nav>
                        </CategoryTab>
                     </Col>
                     <Col lg={7} md={7}>
                        <Tab.Content>
                           {categorytabs.map((item, index) => (
                              <div
                                 key={index}
                                 className={`category__tab--item ${
                                    hoveredCategory === index ||
                                    (hoveredCategory === null && index === 0)
                                       ? 'show'
                                       : ''
                                 }`}
                              >
                                 {console.log(index)}
                                 <div className="category__tab--content">
                                    <div className="category__tab--img">
                                       <img
                                          src={item.image}
                                          alt={item.title}
                                          className="w-100"
                                          width="100%"
                                          height="100%"
                                       />
                                    </div>
                                 </div>
                              </div>
                           ))}
                        </Tab.Content>
                     </Col>
                  </Row>
               </Tab.Container>
            </Container>
         </CategorySection>
      </>
   );
};

export default CategoryBox;
