import React, { memo, useContext } from 'react';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { BlogDetailContainer } from '../blogs/BlogDetail/styles';
import ContactItemBox from '../../components/common/ContactItemBox';
import MessageIcon from '../../icons/MessageIcon';
import ContactIcon from '../../icons/ContactIcon';
import MapIcon from '../../icons/MapIcon';
import { ContactForm, ContactSlider } from './styles';
import { Col, Form, FormControl, FormLabel, Row } from 'react-bootstrap';
import FormButton from '../../components/Form/FormButton';
import SubscribeBox from '../../components/common/SubscribeBox';
import MetaContext from '../../stores/MetaContext';

const ContactPage = memo(() => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('contact-us');
      return (
            <>
                  <BreadcrumbBox title="Contact Us" />

                  <BlogDetailContainer>
                        <ContactSlider
                              items={3}
                              margin={20}
                              className="owl-theme"
                        >
                              <ContactItemBox
                                    icon={<MessageIcon />}
                                    title="Email Address"
                              >
                                    <p>info@innmotek.com</p>
                              </ContactItemBox>
                              <ContactItemBox
                                    icon={<ContactIcon />}
                                    title="Phone Number"
                              >
                                    <p>+91 8081741031 (India)</p>
                                    <p>+971 554398350 (UAE)</p>
                              </ContactItemBox>
                              <ContactItemBox
                                    icon={<MapIcon />}
                                    title="Office Address"
                              >
                                    <p>
                                          Innmotek LLP <br />
                                          GIDA, Uttar Pradesh, 273209
                                    </p>
                                    <p>
                                          Innmotek LLC <br />
                                          6F, Business Center Meydan Road, Nad
                                          Al Sheb Dubai, UAE
                                    </p>
                              </ContactItemBox>
                        </ContactSlider>

                        <Row>
                              <Col lg={4}>
                                    <SubscribeBox />
                              </Col>
                              <Col>
                                    <ContactForm>
                                          <Form>
                                                <Row className="g-4">
                                                      <Col lg={6}>
                                                            <FormLabel>
                                                                  First Name
                                                            </FormLabel>
                                                            <FormControl type="text" />
                                                      </Col>
                                                      <Col lg={6}>
                                                            <FormLabel>
                                                                  Last Name
                                                            </FormLabel>
                                                            <FormControl type="text" />
                                                      </Col>
                                                      <Col lg={6}>
                                                            <FormLabel>
                                                                  Email Address
                                                            </FormLabel>
                                                            <FormControl type="text" />
                                                      </Col>
                                                      <Col lg={6}>
                                                            <FormLabel>
                                                                  Phone No.
                                                            </FormLabel>
                                                            <FormControl type="text" />
                                                      </Col>
                                                      <Col lg={12}>
                                                            <FormLabel>
                                                                  Message
                                                            </FormLabel>
                                                            <FormControl
                                                                  as="textarea"
                                                                  rows={4}
                                                                  type="text"
                                                            />
                                                      </Col>
                                                      <Col lg={12}>
                                                            <FormButton title="Submit" />
                                                      </Col>
                                                </Row>
                                          </Form>
                                    </ContactForm>
                              </Col>
                        </Row>
                  </BlogDetailContainer>
            </>
      );
});

export default ContactPage;
